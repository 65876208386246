@media only screen and (max-width: 900px) {
  .mobile-responsive {
    padding: 0px !important;
  }

  .fee-mobile-responsive {
    width: 100%;
    padding: 0px;
    margin: 0px !important;
    margin: 0px !important;
    padding: 24px !important;
    width: 100% !important;
    background: white;
  }

  .add-fee-header {
    margin-left: 24px;
  }

  button {
    font-size: 10px !important;
  }
  .clinic-management-add-footer {
  }
}

@media only screen and (max-width: 600px) {
  .mobile-responsive {
    padding: 0px !important;
  }

  .fee-mobile-responsive {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
    padding: 24px !important;
    width: 100% !important;
    background: white;
  }

  .add-fee-header {
    margin-left: 24px;
  }

  button {
    font-size: 10px !important;
  }
  .clinic-management-add-footer {
  }
}
