.filterIcon {
    font-size: 36px !important;
}

.mobileViewCls {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    gap: 10px;
    
}


.normalFilter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

@media only screen and (max-width: 509px) {
    .search .MuiTextField-root {
        width: calc(100% - 1px) !important;
    }

    .mobileViewCls .resp-select {
        width: 78vw !important;
        min-width: calc(100% - 1px) !important;
        max-width: calc(100% - 1px) !important;
    }
}

@media only screen and (max-width: 678px) and (min-width: 509px) {
    .search .MuiTextField-root {
        width: calc(100% - 1px) !important;
    }

    .mobileViewCls .resp-select {
        width: calc(100% - 1px);
        min-width: calc(100% - 1px);
        max-width: calc(100% - 1px);
    }
}
@media only screen and (max-width: 900px) and (min-width: 678px) {
    .search .MuiTextField-root {
        width: 567px!important;
    }

    .mobileViewCls .resp-select {
        width: 567px !important;
        min-width: 567px !important;
        max-width: 567px !important;
    }
}
.chipsClass{
    /* width: calc(100% - 20px); */
    flex-wrap: wrap;
}