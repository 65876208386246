body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 12px;
  width: 100%;
}
.MuiButton-contained {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: brown !important;
  color: white !important;
}

/* // Works on Chrome, Edge, and Safari - scrollbar */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0.1;
}

*::-webkit-scrollbar-thumb {
  background: rgba(199, 199, 199, 0.728);
  border-radius: 4px;
  border: none;
  box-shadow: none;
}

/* // Works on Firefox - scrollbar */
* {
  scrollbar-width: thin;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.css-mvoatl-MuiPaper-root-MuiAlert-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(253, 237, 237);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 10px;
  color: rgb(95, 33, 32);
  width: 100%;
  white-space: pre-wrap !important;
}
.Mui-checked {
  color: #d32f2f !important;
}
.Mui-checked + .MuiSwitch-track {
  background-color: #d32f2f !important;
}
.MuiSwitch-switchBase {
  padding-left: 5px !important;
}
.css-1e2bxag-MuiDataGrid-root {
  border: 1px solid rgb(224 224 224 / 48%) !important;
}
