.delete-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-delete-modal-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.inactive {
  background-color: rgb(253, 1, 1) !important;
  color: white !important;
}

.cards-contaienr {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card-scroll {
  overflow-y: scroll;
  padding: 10px;
  max-height: 70vh;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.mobilecard-outline {
  padding: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 0px black;
  border-radius: 4px;
  gap: 5px;
  width: 100%;
}
.card-bot-skeleton-outline {
  background-color: rgb(205, 170, 170);
  display: flex;
  gap: 30px;
  justify-content: center;
}

.card-top-skeleton-outline {
  height: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
