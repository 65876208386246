.mobile-card-container {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  flex-direction: column;
  box-shadow: 1px 1px 4px 0px black;
}

.details-container {
  display: flex;
  gap: 20px;
  padding: 10px;
  font-size: 1.3rem;
}
.details-container span {
  word-wrap: break-word;
}

.action-container {
  border-top: 1px solid rgb(219 186 186);
  display: flex;
  justify-content: space-around;
  background-color: rgb(225, 197, 197);
}

.details-container span {
  font-size: 14px !important;
}
