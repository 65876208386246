.clinic-management-add-container {
  width: 70%;
  position: relative;
  left: 2.5rem;
}

.clinic-management-add-header {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.add-clinic-back-button {
  position: relative;
  bottom: 20px;
}
