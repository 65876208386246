.Active {
  background-color: green !important;
  color: white !important;
}

.Offered {
  background-color: rgb(253, 1, 1) !important;
  color: white !important;
}

.Hired {
  background-color: rgb(46, 47, 46) !important;
  color: white !important;
}

.ABNnumber {
  background-color: rgb(40, 39, 39) !important;
}

.delete-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-delete-modal-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.filled.css-1012bww-MuiChip-root {
  border-color: green;
  color: green;
}

.Not-filled.css-1012bww-MuiChip-root {
  border-color: orange;
  color: orange;
}

.filled.css-1012bww-MuiChip-root svg {
  color: green;
}

.Not-filled.css-1012bww-MuiChip-root svg {
  color: orange;
}
.date_picker{
  min-width: 100% !important;
}
.date_picker .MuiOutlinedInput-root{
  height: 41px;
}
.MuiContainer-maxWidthLg{
  max-width: 100% !important;
}