.home-container {
  display: flex;
  height: 100vh;
}

.home-hero-container-right {
  background-color: #f1f1f18c;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.home-hero-container-right-top {
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 100;
  backdrop-filter: blur(0.1px);
  /* box-shadow: 1px 1px 10px 1px black; */
}

.home-hero-container-right-bottom {
  width: 100%;
  height: auto;
  overflow-y: scroll;
}

.welcome-msg-container {
  margin-left: 15px;
  width: 90%;
}

.table-stats-container {
  width: 90%;
  box-shadow: 2px 2px 100px 2px black;
  border-radius: 10px;
}
.footer {
  position: sticky;
  bottom: 0px;
  z-index: 100;
  backdrop-filter: blur(0.1px);
  width: 100vw;
}

@media only screen and (max-width: 900px) {
  .home-hero-container-right-top {
    backdrop-filter: blur(8px) !important;
  }
}
