.clinic-management-edit-container {
  width: 70%;
  position: relative;
  left: 2.5rem;
  border-radius: 5px;
}

.clinic-management-edit-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.edit-clinic-back-button {
  position: relative;
  bottom: 20px;
}
