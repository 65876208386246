.footer-container {
  height: 6vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 1px 5px 0px black;
  background-color: white;
  position: relative;
}

.footer-container-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.1s ease-in all;
  height: 100%;
  width: 70px;
}

.active-footer {
  background: linear-gradient(
    to top,
    brown 10%,
    rgb(232, 216, 216) 3%
  ) !important;
  color: brown !important;
}

.footer-container-items:active {
  transform: scale(0.98);
}
