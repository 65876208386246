.active {
  background-color: green !important;
  color: white !important;
}
.inactive {
  background-color: rgb(253, 1, 1) !important;
  color: white !important;
}
.delete-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-delete-modal-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.view-agreement-title {
  padding-top: 2rem;
  padding-left: 1rem;
}
